
import {IonGrid, IonPage, IonRow,} from '@ionic/vue';
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";

export default defineComponent({
    name: "Rating",
    components: {
        Header,
        IonPage, IonGrid, IonRow
    },
    setup() {
        return {}
    },
})
